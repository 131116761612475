import request from '@/utils/http';

/**
 * 登录
 * @param payload
 */
export function auth_login(payload) {
    return request({ url: '/system/Login/login', method: 'post', data: payload })
}

/**
 * 用户列表
 * @param payload
 */
export function user_list(payload) {
    return request({ url: '/system/AdminUser/lists', method: 'post', data: payload })
}

/**
 * 更新用户
 * @param payload
 */
export function user_create(payload) {
    return request({ url: '/system/AdminUser/create', method: 'post', data: payload })
}

/**
 * 更新用户
 * @param payload
 */
export function user_update(payload) {
    return request({ url: '/system/AdminUser/update', method: 'post', data: payload })
}

/**
 * 删除用户
 * @param payload
 */
export function user_del(payload) {
    return request({ url: '/system/AdminUser/delete', method: 'post', data: payload })
}
export function roles(payload) {
    return request({ url: '/system/AdminUser/getRoles', method: 'post', data: payload })
}
/*****************角色相关********************/

/**
 * 角色列表
 * @param payload
 */
export function role_list(payload) {
    return request({ url: '/system/AdminRole/lists', method: 'post', data: payload })
}

/**
 * 更新角色
 * @param payload
 */
export function role_create(payload) {
    return request({ url: '/system/AdminRole/create', method: 'post', data: payload })
}

/**
 * 更新角色
 * @param payload
 */
export function role_update(payload) {
    return request({ url: '/system/AdminRole/update', method: 'post', data: payload })
}

/**
 * 角色详情
 * @param payload
 */
export function role_detail(payload) {
    return request({ url: '/system/AdminRole/detail', method: 'post', data: payload })
}

/**
 * 删除用户
 * @param payload
 */
export function role_del(payload) {
    return request({ url: '/system/AdminRole/delete', method: 'post', data: payload })
}



/**
 * 菜单列表
 * @param payload
 */
export function menu_list(payload) {
    return request({ url: '/system/AdminMenu/lists', method: 'post', data: payload })
}
/**
 * 树形菜单列表
 * @param payload
 */
export function tree_list(payload) {
    return request({ url: '/system/AdminMenu/getMenuTreeData', method: 'post', data: payload })
}
/**
 * 更新菜单
 * @param payload
 */
export function update(payload) {
    return request({ url: '/system/AdminMenu/update', method: 'post', data: payload })
}
/**
 * 添加菜单
 * @param payload
 */
export function create(payload) {
    return request({ url: '/system/AdminMenu/create', method: 'post', data: payload })
}
/**
 * 删除菜单
 * @param payload
 */
export function del(payload) {
    return request({ url: '/system/AdminMenu/delete', method: 'post', data: payload })
}

/**
 * 获取菜单信息
 * @param payload
 */
export function info(payload) {
    return request({ url: '/system/AdminMenu/info', method: 'post', data: payload })
}

export function saveApiPermission(payload) {
    return request({ url: '/system/AdminMenu/saveApiPermission', method: 'post', data: payload })
}

/**
 * 权限列表
 * @param payload
 */
export function api_list(payload) {
    return request({ url: '/system/AdminApi/lists', method: 'post', data: payload })
}
/**
 * 树形列表
 * @param payload
 */
export function api_tree_list(payload) {
    return request({ url: '/system/AdminApi/getMenuTreeData', method: 'post', data: payload })
}


/**
 * 删除
 * @param payload
 */
export function api_del(payload) {
    return request({ url: '/system/AdminApi/delete', method: 'post', data: payload })
}

/**
 * 删除
 * @param payload
 */
export function api_add(payload) {
    return request({ url: '/system/AdminApi/create', method: 'post', data: payload })
}

/**
 * 编辑
 * @param payload
 */
export function api_edit(payload) {
    return request({ url: '/system/AdminApi/update', method: 'post', data: payload })
}


/**
 * 获取信息
 * @param payload
 */
export function api_info(payload) {
    return request({ url: '/system/AdminApi/info', method: 'post', data: payload })
}



/**
 * 部门列表
 * @param payload
 */
export function dept_list(payload) {
    return request({ url: '/system/AdminDept/lists', method: 'post', data: payload })
}

/**
 * 部门人员列表
 * @param payload
 */
export function dept_user_list(payload) {
    return request({ url: '/system/AdminDept/userlists', method: 'post', data: payload })
}

/**
 * 创建部门
 * @param payload
 */
export function dept_create(payload) {
    return request({ url: '/system/AdminDept/create', method: 'post', data: payload })
}

/**
 * 更新部门
 * @param payload
 */
export function dept_update(payload) {
    return request({ url: '/system/AdminDept/update', method: 'post', data: payload })
}

/**
 * 删除部门
 * @param payload
 */
export function dept_del(payload) {
    return request({ url: '/system/AdminDept/delete', method: 'post', data: payload })
}

/**
 * 列表
 * @param payload
 */
export function database(payload) {
    return request({ url: '/system/System/database', method: 'post', data: payload })
}


/**
 * 同步导航栏目数据
 * @param payload
 */
export function sync_nav(payload) {
    return request({ url: 'system/SyncNav/index', method: 'post', data: payload })
}